import React, { Component } from 'react'

class Kontakt extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
      <div className="row">
        <div className="col-xl-12">
          <p className="pt-3 lead text-center">Kontakta mig</p>
        </div>
      </div>
      </React.Fragment>
    )
  }
}

export default Kontakt
