import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import logo from './sofias.png'
import { Navbar, Nav } from 'react-bootstrap';



const HeaderMenu = props => {
  return (
    <React.Fragment>
      <Navbar className="nav-color" expand='md'>
        <div className="container-fluid">
          <Navbar.Brand href="/">
            <img src={logo} style={{width:180, marginTop: -7}} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='mr-auto'>
              <Nav.Link className="text-dark" href="/">Välkommen</Nav.Link>
              <Nav.Link className="text-dark" href="/priser">Prislista</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

class Menu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
          <HeaderMenu/>
      </React.Fragment>
    )
  }
}

export default Menu
