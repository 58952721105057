import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '350px',
  height: '350px'
};


export class GoogleMap extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
            <Map
              google={this.props.google}
              zoom={15}
              style={mapStyles}
              initialCenter={{ lat: 56.675179, lng: 12.857422}}
            >
              <Marker position={{lat:56.675179, lng: 12.857422}} />
            </Map>
    )
  }
}


export default GoogleApiWrapper({
  apiKey: ("AIzaSyA4Doh-CnhFYqKa3tO_E0kwDX9Fm_2IZb0")
})(GoogleMap)
