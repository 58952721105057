import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';


import Menu from './Navbar'
import Home from './Home'
import Priser from './Priser1'
import Kontakt from './Kontakt'
import GoogleMap from './GoogleMap'
import './App.css';
import ReactGA from 'react-ga';

 ReactGA.initialize('UA-176813594-1');
 ReactGA.pageview('/homepage');

function App() {
  return (


    <BrowserRouter>
      <div>
        <div>
          <div className="shadow-sm">
            <Menu />
          </div>
          <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/" component={Home} exact/>
            <Route path="/priser" component={Priser}/>
          </Switch>
        </div>
      </div>
    </BrowserRouter>


  );
}

export default App;
