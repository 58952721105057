import React, { Component } from 'react'
import GoogleMap from './GoogleMap'
import logo from './sofias.png'
import insta from './insta.png'
import fb from './facebook.png'

class Home extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12 mt-5 text-center">
              <img src={logo} alt="Logo" style={{width: '70%'}}/>
              <p className="mb-5 Lspacing lead text-center">Ändringssömnad & Lagning</p>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div>
                <div className="h6 border my-background2 p-5 text-white">
                <p>Jag hjälper dig med att fixa till dina plagg, nya som gamla. Jag lagar förslitningar, byter blixtlås, lägger upp, syr in eller finner en annan lösning
                så att ditt plagg hänger med ett tag till eller att passformen blir bra för just dig.</p>
                <p>I första hand hjälper jag dig med ändringssömnad och lagningar av kläder, gäller dam, herr och barn. Jag utför även
                 andra typer av sömnad.
                </p>
                <p>Välkommen med frågor eller funderingar. Kontakta mig gärna via mail eller telefon för rådfrågning eller för att boka en tid. </p>
                <p>Alla kläder och textilier som lämnas in ska vara tvättade och rena.</p>
                <div>
                <br>
                </br>
                  <a href="https://www.instagram.com/sofiassomnad/?hl=sv">
                    <img src={insta} alt="Logo" style={{width: '40px'}}/>
                  </a>
                  &nbsp;&nbsp;
                  <a href="https://www.facebook.com/Sofias-S%C3%B6mnad-113067843805310">
                    <img src={fb} alt="Logo" style={{width: '40px'}}/>
                  </a>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="pt-4 col-xl-6 col-lg-7 col-md-6 col-sm-12 col-12">
              <div className="border my-background1 p-5 text-white"><strong>Öppettider</strong>
                <p>Kontakta mig gärna för att boka tid.
                Jag är flexibel och kan lätt anpassa mig efter dina behov.</p>

                <strong>Kontakt</strong>
                <p className="mb-0">Sofia's Sömnad</p>
                <p className="mb-0">Klammerdammsgatan 8, 1tr</p>
                <p>302 42 Halmstad</p>
                <p className="mb-0">Telefon: 0708-578125</p>
                <p className="mb-0">Email: sof@sofiassomnad.se</p>
              </div>
            </div>
            <div className="mx-auto pt-4 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12">
              <GoogleMap />
            </div>
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default Home
