import React, { Component } from 'react'

class Priser extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
      <div className="row">
        <div className="col-12">
          <p className="mt-5 Lspacing lead text-center">Prislista 2021</p>
        </div>
      </div>
      <div className="row">
        <div className="col-2">
        </div>
        <div className="col-8">
          <div className="p-3 text-center">
            <p>Prislistan är under framtagande. <strong>Exakt pris ges efter visning av plagget. </strong>
            Priserna inkluderar material och moms.
            Pris tillkommer om material eller detaljer avviker och gör arbetet mer komplicerat eller om
            handsömnad krävs.
            </p>
            <p className="font-italic">Alla kläder och textilier som lämnas in ska vara tvättade och rena.</p>
          </div>
        </div>
        <div className="col-2">
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <p><strong>Byxor</strong></p>
          </div>
          <div className="col-6">
            <p><strong>Jeans</strong></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Upplägg, kortning, förlängning</p>
          </div>
          <div className="col-3">
            <p> </p>
          </div>
          <div className="col-3">
            <p>Upplägg, kortning, förlängning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag av benvidd (halva)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Intag av benvidd (halva)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag av benvidd (hela)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Intag av benvidd (hela)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag/ Utlägg midja</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Intag/ Utlägg midja</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Byte av dragkedja (inkl, dragkedja)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Byte av dragkedja (inkl, dragkedja)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Byta/Sy nya hällor</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Byta/Sy nya hällor</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Laga hål + förstärkning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Laga hål + förstärkning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
      </div>
      <br/>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <p><strong>Kjol</strong></p>
          </div>
          <div className="col-6">
            <p><strong>Klänning</strong></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Upplägg, kortning, förlängning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Upplägg, kortning, förlängning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag/ Utlägg midja</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Intag/ Utlägg midja</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Byte av dragkedja (inkl, dragkedja)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Byte av dragkedja (inkl, dragkedja)</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
      </div>


      <br/>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <p><strong>Blus/Tröja</strong></p>
          </div>
          <div className="col-6">
            <p><strong>Jacka</strong></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Ärmkortning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Byte av dragkedja</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag rygg/sidor</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p> </p>
          </div>
          <div className="col-3">
            <p> </p>
          </div>
        </div>
      </div>

      <br/>

      <div className="container">
        <div className="row">
          <div className="col-6">
            <p><strong>Kavaj/Rock/Kappa</strong></p>
          </div>
          <div className="col-6">
            <p><strong>Övrigt</strong></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Nytt foder</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Lagning trasig fåll</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Ärm kortning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Lagning hål/reva</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Ärm kortning m. sprund</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Lagning av fickpåse</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Ärm förlängning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Tygförstärkning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag rygg</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Knapphål - maskinsytt</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Intag sidor</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p>Märken på overall</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <p>Längd upplägg, kortning</p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
          <div className="col-3">
            <p></p>
          </div>
        </div>
      </div>
      <br/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p><strong>Balklänning/Brudklänning</strong></p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p></p>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div className="col-12">
        <p className="pt-3 lead text-center">Jag har F-skattesedel</p>
      </div>
      <br/>
      <br/>
      </React.Fragment>
    )
  }
}






export default Priser
